@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply h-full;
}

body {
    @apply h-full;
}

main {
  @apply h-full;
}